import kebabCase from 'lodash/kebabCase';

import {
  CATEGORIES_NAMES_LIST,
  MONTHS_LIST,
  TAGS,
} from '@/pages/shopify.com/($locale)/news/enums/enums';

interface CategoryThemeItem {
  textColor: string;
  borderColor: string;
}

export const getColorByCategoryRedesign = (
  category = '',
): CategoryThemeItem => {
  switch (kebabCase(category)) {
    case 'all':
    case 'product':
      return {
        textColor: 'text-kale-10',
        borderColor: 'border-kale-10',
      };
    case 'company':
      return {
        textColor: 'text-aloe-10',
        borderColor: 'border-aloe-10',
      };
    case 'press-releases':
      return {
        textColor: 'text-lime-10',
        borderColor: 'border-lime-10',
      };
    case 'pov':
      return {
        textColor: 'text-dragonfruit-10',
        borderColor: 'border-dragonfruit-10',
      };
    default:
      return {
        textColor: 'text-white',
        borderColor: 'border-lavender-30',
      };
  }
};

export const formatDateString = (
  dateString: string,
  locale: string,
): string => {
  return new Date(dateString).toLocaleString(locale, {
    timeZone: 'America/Toronto',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const generateSearchQuery = ({
  query,
  categories,
  years,
  months,
}: {
  query: string;
  categories: string[];
  years: string[];
  months: string[];
}) => {
  let queryParam = '';
  let categoriesParam = '';
  let dateRangeParam = '';

  if (query) {
    queryParam = query;
  }
  if (categories.length) {
    categoriesParam = categories.reduce(
      (acc, curr) => (acc ? `${acc} OR tag:'${curr}'` : `tag:'${curr}'`),
      categoriesParam,
    );
  }
  if (years.length) {
    if (years.length === 1) {
      const year = years[0];

      if (months.length) {
        dateRangeParam = months.reduce(
          (acc, curr) =>
            !acc
              ? `tag:'${year}' AND tag:'${
                  MONTHS_LIST[parseInt(curr) - 1].name
                }'`
              : `${acc} OR tag:'${MONTHS_LIST[parseInt(curr) - 1].name}'`,
          dateRangeParam,
        );
      } else {
        dateRangeParam = `tag:'${year}'`;
      }
    } else {
      dateRangeParam = years.reduce(
        (acc, curr) => (!acc ? `tag:'${curr}'` : `${acc} OR tag:'${curr}'`),
        dateRangeParam,
      );
    }
  }

  let searchQuery = '';
  if (queryParam) {
    searchQuery += queryParam;
  }
  if (categoriesParam) {
    searchQuery += ` AND (${categoriesParam})`;
  } else {
    searchQuery += ` NOT (tag:'${TAGS.merchantStory}' OR tag:'${TAGS.heroVideo}')`;
  }
  if (dateRangeParam) {
    searchQuery += ` AND (${dateRangeParam})`;
  }

  return searchQuery;
};

export const categoryTagsFromTags = (tags: string[] = []) =>
  tags
    .filter((tag) => CATEGORIES_NAMES_LIST.find((el) => el.value === tag))
    .map(
      (tag: string) =>
        CATEGORIES_NAMES_LIST.find((el: any) => el.value === tag)?.name || '',
    );

export const replaceTag = (category: string | undefined) => {
  switch (category) {
    case 'pov':
      return TAGS.future;
    default:
      return category;
  }
};
