export const CATEGORIES_NAMES_LIST = [
  {name: 'Press Releases', value: 'Press Releases'},
  {name: 'Product', value: 'Product news'},
  {name: 'Company', value: 'Company news'},
  {name: 'Data Insights', value: 'Data Insights'},
  {name: 'POV', value: 'Future of Commerce'},
  {name: 'International News', value: 'International News'},
];

export const MONTHS_LIST = [
  {name: 'January', number: '01'},
  {name: 'February', number: '02'},
  {name: 'March', number: '03'},
  {name: 'April', number: '04'},
  {name: 'May', number: '05'},
  {name: 'June', number: '06'},
  {name: 'July', number: '07'},
  {name: 'August', number: '08'},
  {name: 'September', number: '09'},
  {name: 'October', number: '10'},
  {name: 'November', number: '11'},
  {name: 'December', number: '12'},
];

export const TAGS = {
  company: 'Company news',
  product: 'Product news',
  future: 'Future of Commerce',
  press: 'Press Releases',
  merchantStory: 'merchant-story',
  heroVideo: 'video-feature',
};
