import {twMerge} from '@/stylesheets/twMerge';
import {useSiteData} from '@/components/shared/Page/SiteData';
import Typography from '@/components/base/elements/Typography/Typography';

import {formatDateString, getColorByCategoryRedesign} from '../utils/utils';

interface StoryCardMetaProps {
  className?: string;
  date?: string;
  tags?: string[];
  textSize?: string;
}

export default function StoryCardMeta({
  date,
  tags,
  className = '',
  textSize = 'body-base',
}: StoryCardMetaProps) {
  const {site} = useSiteData();
  const hasTags = !!tags?.length;
  const desktopFont = 'md:text-body-base md:font-normal';

  return (
    <p
      className={twMerge(
        'flex flex-wrap items-center mb-sm text-base text-shade-30',
        className,
      )}
    >
      {hasTags &&
        tags?.map((tag: string, idx: number) => {
          return (
            <span key={idx}>
              <Typography
                as="span"
                size={textSize}
                isRichtext={false}
                className={twMerge(
                  'uppercase',
                  desktopFont,
                  getColorByCategoryRedesign(tag).textColor,
                )}
              >
                {tag}
              </Typography>
              {tags.length !== idx + 1 && (
                <Typography as="span">,&nbsp;</Typography>
              )}
            </span>
          );
        })}
      {hasTags && date && (
        <span className="w-[6px] h-[6px] mx-3 rounded-full bg-shade-30"></span>
      )}
      {date && (
        <Typography as="span" size={textSize} isRichtext={false}>
          {formatDateString(date, site.locale)}
        </Typography>
      )}
    </p>
  );
}
